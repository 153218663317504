<template>
    <div class="card">
        <loader  v-if="isLoading" />
        
        <div v-else-if="subscribers.length > 0" class="listview listview--bordered">
            <div class="listview__item" v-for="(subscriber, i) in subscribers" :key="i">
                <!-- <img class="listview__img_news" alt="" :src="subscriber.photo" v-if="subscriber.photo != ''"> -->
                <!-- <i class="avatar-char bg-light-blue" v-else></i> -->
                <i class="avatar-char bg-light-blue">{{ subscriber.name[0] }}</i>
                <div class="listview__content"> 
                    <div class="listview__heading">{{ subscriber.name }}</div>
                    <p>{{ subscriber.email }}</p>
                    <p>{{ subscriber.status }}</p>
                </div>
                <div class="actions listview__actions">
                    <!-- <i class="actions__item zmdi zmdi-eye"></i> -->
                </div>
            </div>
        </div>
        
        <div class="notfound" v-else>No subscribers found</div>

    </div>
</template>

<script>
export default {
    data(){
        return {
            subscribers: [],
            isLoading: false,
        }
    },
    created(){
        this.loadDonation()
    },
    methods: {
        async loadDonation(){
            this.isLoading = true
            const snapshot = await this.$db.collection("subscribers")
                .orderBy("createdAt", "desc")
                .get()
            const subscriberArray = []
            snapshot.forEach(doc => {
                subscriberArray.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            this.subscribers = subscriberArray
            // console.log("TOTAL SUBSCRIBERS subscriberArray", subscriberArray.length );
            this.isLoading = false
        },
        makeDate(date){
            return date && typeof date !== undefined ? this.$moment(date.toDate()).format("DD/MM/YYYY HH:mm:ss a") : "NIL"
        }
    },
    components: {
        "loader": () => import("@/components/Loaders/Processing.vue")
    }
}
</script>